import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react"
import "../styles/house.scss"

export default function OmAtByggeHus() {
  return (
    <Layout>
      <Seo title="Steen Lauth" />
      <section className="house">
        <div className="upperWrapper">
          <div className="imageWrapper">
            <StaticImage
              src={"../images/house/house.jpeg"}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Steen Lauth | Tømrer og snedker "
            />
          </div>
          <div className="textWrapper">
            <h2>At bygge hus</h2>
            <p>
              Det er ikke så nem en opgave for bygherre at få bygget et hus, som
              mange typehusfirmaer påstår. Vil man have et individuelt hus med
              flotte og tilpassede løsninger, er der mange valg, man må sætte
              sig ind i. Derfor bør man starte hos en arkitekt, og ikke en
              entreprenør. Jeg er erfaren bygherrerådgiver, men hvis jeg skal
              bygge jeres hus, skal I først have tegninger fra en arkitekt -
              enten som idéoplæg eller hovedprojekt.
            </p>
          </div>
        </div>

        <div className="lowerWrapper">
          <Accordion allowToggle>
            <h2 className="title">Muligheder</h2>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Totalentreprise{" "}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Totalentreprenøren har ansvar for: Nødvendig rådgivning, altså
                arkitekter, ingeniører etc. Byggeriet og de enkelte faggrupper
                Styring af byggeriet Styring af arbejdsmiljøet.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Hovedentreprise{" "}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Entreprenøren har ansvar for: Byggeriet og de enkelte faggrupper
                Styring af byggeriet Styring af arbejdsmiljøet
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Partnerring{" "}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Partnering kan udføres som en hovedentreprise med åbent regnskab
                og en estimeret budgetramme, hvori der kan indbygges økonomiske
                incitamentsstukturer til at styre byggeriet. I en
                partneringmodel må det forventes, at bygherre sætter sig dybere
                ind i de forskellige byggetekniske områder for at få størst
                mulig udbytte af kontraktformen.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Timeløn{" "}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Her aftales en fast timeløn og avance på materialer. Ved
                timelønsarbejde har bygherre størst mulighed for at ændre
                projektet løbende undervejs.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <StaticImage
            src={"../images/house/ceiling.jpeg"}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Steen Lauth | Tømrer og snedker "
          />
        </div>
      </section>
    </Layout>
  )
}
